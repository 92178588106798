import React, { Fragment } from 'react'
import HeroBanner from '../Widget/HeroBanner/HeroBanner'
import PolicyBlock from '../Widget/NumberList/PolicyBlock'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import './terms.scss'
import { useFetchPage } from '../../Queries/Page/useFetchPage'
import Loader from 'react-loaders'

const TermsConditions = ({ page_uuid, handleClose }) => {
  const { data, isLoading } = useFetchPage(page_uuid)
  const isDesktop = useMediaQuery('(min-width:768px)')

  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '5rem', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  let termsConditionDetailsWidget
  data?.component_call_sections?.map((section) => {
    if (section?.name === 'terms_and_conditions_details') {
      termsConditionDetailsWidget = section?.widgets?.[0]
    }
  })

  return (
    <Fragment>
      <div
        className='mt-5 position-relative terms-condition-privacy-policy'
        style={{ border: '1px solid #8E8E8E', borderTopRightRadius: '10px', marginRight: '0.6rem', width: '98%', height: '100%' }}>
        <i
          className='fa fa-times-circle position-absolute'
          onClick={handleClose}
          style={{ color: '#F87171', cursor: 'pointer', fontSize: 18, right: -10, top: -10 }}></i>

        {termsConditionDetailsWidget && (
          <div>
            <PolicyBlock data={termsConditionDetailsWidget?.widget_data} widget_uuid={termsConditionDetailsWidget?.uuid} />
          </div>
        )}
        {/* <iframe
          src='/terms-and-condition-list?isShowOnlyContent=true&hideHeroBanner=true'
          style={{ width: '98%', height: '100%', border: '0px', marginTop: '1rem', borderRadius: '10px' }}></iframe> */}
      </div>
    </Fragment>
  )
}

export default TermsConditions
