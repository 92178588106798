
import { getDeviceId } from './device'

const GA_MEASUREMENT_ID = 'G-KY94R2GFW0' 

export const initGA = () => {
  const userId = getDeviceId()
  window.gtag('config', GA_MEASUREMENT_ID, {
    user_id: userId,
  })

  window.gtag('set', 'user_properties', {
    device_id: userId,
  })
}

export const logClickEvent = ({ label, path, tag, timestamp }) => {
  window.gtag('event', 'click_event', {
    event_category: 'user_click',
    event_label: `${label} | ${tag} | ${path}`,
    value: 1,
    timestamp,
  })
}
