import Loader from 'react-loaders'
import { useFetchRoleWisePermission } from '../../../Queries/Permission/useGetRoleWisePermission'
import { Collapse, ListGroup, ListGroupItem, UncontrolledCollapse } from 'reactstrap'
import PermissionOverviewRole from './permissionOverviewRole'
import { useEffect, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import { getRolesFromPermissions } from '../../../Helper/permissionHelper'

const PermissionOverview = () => {
  const [roles, setRoles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  // const { data, isLoading } = useFetchRoleWisePermission()

  useEffect(() => {
    setIsLoading(true)
    request('/get-roles', 'POST').then((res) => {
      const roles = getRolesFromPermissions(res?.data?.data)
      setRoles(roles)

      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center' style={{ marginTop: '4rem', marginBottom: '8rem' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )
  }

  return (
    <div>
      <ListGroup>
        {roles?.map((item) => {
          return <Role item={item} />
        })}
      </ListGroup>
    </div>
  )
}

const Role = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <ListGroupItem className='subtitle1 cursor-pointer' onClick={() => setIsOpen(!isOpen)} style={{ background: '#00000010' }}>
        {item?.role}
      </ListGroupItem>

      <Collapse isOpen={isOpen}>
        {isOpen && <PermissionOverviewRole permissions={item?.permissions} id={item?.role_id} key={item?.role_id} />}
      </Collapse>
    </div>
  )
}

export default PermissionOverview
