// hooks/useClickTracker.js
import { useEffect } from 'react'
import { logClickEvent } from './analytics'


export const useClickTracker = () => {
  useEffect(() => {
    const handler = (event) => {
      const target = event.target
      const text = target.innerText || target.getAttribute('aria-label') || 'unknown'
      const tag = target.tagName
      const path = window.location.pathname
      const timestamp = new Date().toISOString()

      logClickEvent({
        label: text,
        tag,
        path,
        timestamp,
      })
    }

    document.addEventListener('click', handler)
    return () => document.removeEventListener('click', handler)
  }, [])
}
